import React, { useState } from "react";
import hero from "../assets/hero.jpg";
import axios from "axios";
import Drawer from "./Drawer";

const Hero: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log(localStorage);
    event.preventDefault();
    const form = event.currentTarget;
    const consentCheckbox = document.getElementById(
      "consent",
    ) as HTMLInputElement;

    if (!consentCheckbox.checked) {
      alert(
        "Należy wyrazić zgodę na przetwarzanie danych osobowych według Polityki Prywatności.",
      );
      return;
    }

    const name = (form.elements.namedItem("name") as HTMLInputElement).value;
    const surname = (form.elements.namedItem("surname") as HTMLInputElement)
      .value;
    const email = (form.elements.namedItem("email") as HTMLInputElement).value;
    const phone = (form.elements.namedItem("phone") as HTMLInputElement).value;
    const course = (form.elements.namedItem("course") as HTMLSelectElement)
      .value;

    if (name.length > 50) {
      alert("Imię nie może przekraczać 50 znaków.");
      return;
    }

    if (surname.length > 50) {
      alert("Nazwisko nie może przekraczać 50 znaków.");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert("Proszę podać poprawny adres email.");
      return;
    }

    const phonePattern = /^[0-9]{9}$/;
    if (!phonePattern.test(phone)) {
      alert("Proszę podać poprawny numer telefonu (9 cyfr).");
      return;
    }

    const lastSubmitted = localStorage.getItem("lastSubmitted");
    const now = new Date().getTime();

    if (lastSubmitted && now - parseInt(lastSubmitted) < 5 * 60 * 1000) {
      alert("Możesz wysłać formularz tylko raz na 5 minut.");
      return;
    }

    const data = {
      name,
      surname,
      email,
      phone,
      course,
    };

    try {
      const response = await axios.post(
        "https://frog01-21359.wykr.es/send-email",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      alert("Dane pomyślnie przesłane!");
      localStorage.setItem("lastSubmitted", now.toString());
      console.log(response.data);
    } catch (error: any) {
      alert("Błąd przy wysyłaniu danych:\n" + error.message);
    }
  };

  return (
    <section
      id="hero"
      className="dark:text-white appear relative w-full min-h-screen lg:h-screen flex flex-col lg:flex-row justify-center items-center"
    >
      <img
        src={hero}
        alt="hero"
        className="absolute inset-0 w-full h-full object-cover pointer-events-none select-none shadow-lg shadow-gray-400"
        style={{ objectPosition: "75%" }}
      />
      <div className="font-Montserrat relative z-10 flex flex-col lg:w-1/2 p-6 text-white text-center lg:text-left lg:ml-10 mt-10 lg:mt-0">
        <h1 className="text-4xl lg:text-5xl font-bold mb-4">
          Szukasz korepetycji? Dobrze trafiłeś!
        </h1>
        <p className="text-lg lg:text-xl mb-6">
          Zgłoś się do nas, a pomożemy Ci zrealizować 
          <br />
          twoje szkole cele!
        </p>
      </div>
      <div className="dark:bg-amdark relative z-10 w-full max-w-md p-6 bg-white shadow-lg rounded-lg lg:mt-0 mx-auto lg:mx-0 lg:w-1/3 hover:translate-y-[-10px] transition-transform mb-4">
        <h2 className="text-2xl font-bold dark:text-white mb-5">
          Wypełnij formularz
        </h2>
        <form
          method="POST"
          className="flex flex-col space-y-4"
          noValidate
          onSubmit={handleSubmit}
        >
          <input
            name="name"
            type="text"
            placeholder="Imię"
            className="p-3 border border-gray-300 rounded-lg dark:bg-inputamdark"
            required
          />
          <input
            name="surname"
            type="text"
            placeholder="Nazwisko"
            className="p-3 border border-gray-300 rounded-lg dark:bg-inputamdark"
            required
          />
          <input
            name="email"
            type="email"
            placeholder="Email"
            className="p-3 border border-gray-300 rounded-lg dark:bg-inputamdark"
            required
          />
          <input
            name="phone"
            type="text"
            placeholder="Numer telefonu"
            className="p-3 border border-gray-300 rounded-lg dark:bg-inputamdark"
            required
          />
          <select
            name="course"
            id="courses-select"
            className="p-3 border rounded-lg border-gray-300 dark:bg-inputamdark"
            required
          >
            <option value="">Wybierz rodzaj zajęć</option>
            <option value="kurs1">Matematyka 8 klasa</option>
            <option value="kurs2">Matematyka matura rozszerzona</option>
            <option value="kurs3">Matematyka matura podstawowa</option>
            <option value="kurs4">Chemia 8 klasa</option>
            <option value="kurs5">Chemia matura rozszerzona</option>
            <option value="kurs6">Informatyka matura rozszerzona</option>
            <option value="kurs7">
              Korepetycje z bieżącego materiału chemia/matematyka/informatyka
            </option>
          </select>
          <div className="flex items-start">
            <input
              type="checkbox"
              id="consent"
              className="mt-1 mr-2"
              required
            />
            <label htmlFor="consent" className="text-sm">
              Wyrażam zgodę na przetwarzanie moich danych osobowych w celu
              nawiązania kontaktu i omówienia szczegółów zapisów na kursy
              zgodnie z{" "}
              <a
                href="#!"
                onClick={handleDrawerOpen}
                className="text-teal-500 underline"
              >
                Polityką Prywatności
              </a>
              .
            </label>
          </div>
          <button
            type="submit"
            className="hover:bg-teal-300 transition duration-500 p-3 bg-black text-white rounded-lg"
          >
            Wyślij
          </button>
        </form>
      </div>
      <Drawer isOpen={isDrawerOpen} onClose={handleDrawerClose}>
        <h2 className="text-xl font-bold mb-4">Polityka Prywatności</h2>
        <h2 className="font-semibold">Informacja RODO</h2>
        <p>
          W realizacji obowiązków wynikających z postanowień Rozporządzenia
          Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016
          r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
          osobowych i w sprawie swobodnego przepływu takich danych oraz
          uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
          (Dz. U. UE. L. z 2016 r. Nr 119, str. 1 z późn. zm.) – dalej: „RODO”
          informujemy, że:
        </p>
        <p>
          Administratorem danych osobowych jest spółka Akademia Maturzysty sp.k.
          z adresem i siedzibą przy ul. Szpitalnej 42 32-300 Olkusz, NIP:
          6372211340{" "}
          <span className="font-semibold inline">(dalej Akademia).</span>
        </p>
        <p>
          Akademia przetwarza dane osobowe klientów oraz osób zainteresowanych
          współpracą w zakresie niezbędnym do realizacji zawartych umów, a także
          dane osób trzecich wynikające z akt sprawy i zgromadzonego materiału
          dowodowego oraz dokumentacji przekazanej przez klienta lub sąd w
          oparciu i w granicach wynikających z przepisów Rozdziału 1a Ustawy z
          dnia 6 lipca 1982 r. o radcach prawnych, tj. na podstawie art. 6 ust.
          1 lit. c RODO. Oprócz tego Akademia przetwarza dane osobowe
          współpracowników, partnerów biznesowych i ich reprezentantów oraz osób
          działających na ich rzecz w związku z zawartymi umowami, tj. na
          podstawie art. 6 ust. 1 lit. b RODO. Akademia może przetwarzać dane
          klientów oraz współpracowników w celach ewentualnego podjęcia
          współpracy w przyszłości, a także w celu dochodzenia lub ochrony przed
          roszczeniami, co stanowi uzasadniony prawnie cel przetwarzania, tj. w
          oparciu o art. 6 ust. 1 lit. f RODO. Po zakończeniu współpracy, dane
          przechowywane są przez okres wynikający z przepisów prawa w zakresie
          dokumentów prowadzonych spraw oraz dokumentacji finansowo księgowej
          lub przez okres odpowiadający przedawnieniu roszczeń w zakresie innych
          spraw.
        </p>
        <p>
          Poprzez stronę internetową za pośrednictwem plików cookies lub
          podobnym technologii przetwarzane są dane osobowe w zakresie numeru
          IP, lokalizacji osób odwiedzających serwis, historii przeglądania. W
          odniesieniu do osób, które nawiążą kontakt bezpośredni z
          administratorem - adres poczty elektronicznej. Celem przetwarzania
          jest analiza statystyczna ruchu na stronie oraz informacje o
          ewentualnych błędach służące rozwojowi witryny Kancelarii, co stanowi
          uzasadniony prawnie interes administratora w rozumieniu art. 6 ust. 1
          lit. f RODO.
        </p>
        <p>
          Przetwarzanie danych z plików cookies lub podobnych technologii można
          deaktywować z poziomu przeglądarki internetowej użytkownika –
          szczegóły dostępne są w opisie funkcji poszczególnych przeglądarek.
          Zablokowanie przetwarzania danych może ograniczyć niektóre funkcje
          serwisu względem jego użytkownika.
        </p>
        <p>
          Dane przetwarzane przez Kancelarię mogą być udostępniane partnerom
          technologicznym Administratora – np. firmie google lub firmie
          hostingowej w celu prawidłowej realizacji usługi i przechowywania
          informacji przetwarzanych w sposób cyfrowy.
        </p>
        <p>
          Administrator nie wyznaczył inspektora ochrony danych. Kontakt z
          Kancelarią w zakresie dotyczącym przetwarzania danych osobowych
          możliwy jest pod adresem akademiamaturzysty.kontakt@gmail.com.
        </p>
        <p>
          Dane nie podlegają procesowi profilowania ani na ich podstawie nie są
          podejmowane w sposób zautomatyzowany żadne decyzje.
        </p>
        <p>
          Każdej osobie, której dane są przetwarzane przez Kancelarię
          przysługuje prawo do wglądu i informacji czy i w jakim zakresie jej
          dane podlegają przetwarzaniu przez administratora, sprostowania i
          korekty danych, sprzeciwu wobec przetwarzania danych osobowych w
          określony sposób, żądania usunięcia przetwarzanych danych jak również
          złożenia skargi do organu nadzoru, którym jest Prezes Urzędu Ochrony
          Danych Osobowych (www.uodo.gov.pl).
        </p>
      </Drawer>
    </section>
  );
};

export default Hero;
