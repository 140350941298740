import React from 'react';

interface SocialLink {
  href: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

interface TeamMemberProps {
  img: string;
  name: string;
  surname: string;
  speciality: string;
  description: string;
  socialLinks: SocialLink[];
}

const TeamMember: React.FC<TeamMemberProps> = ({
  img,
  name,
  surname,
  speciality,
  description,
  socialLinks,
}) => {
  return (
    <div className="dark:bg-cardamdark dark:text-white rounded-lg overflow-hidden shadow-lg transform duration-500 transition-transform hover:translate-y-[-10px] hover:shadow-2xl m-4 w-full sm:w-80 border border-teal-500 flex flex-col">
      <div>
        <img
          className="w-full h-auto object-cover"
          src={img}
          alt={`${name} ${surname}`}
        />
      </div>
      <div className="text-center flex-col flex mx-5 flex-grow">
        <h3 className="text-xl font-bold">
          {name} {surname}
        </h3>
        <h4 className="text-teal-600">{speciality}</h4>
        <p className="dark:text-white text-gray-600">{description}</p>
        <div className="h-16 flex justify-center items-center space-x-4 mt-auto">
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              className="dark:text-white text-gray-600 hover:text-teal-600"
            >
              <link.icon className="w-6 h-6" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;