import React from "react";

import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import PriceList from "./components/PriceList";
import Contact from "./components/Contact";
import FooterCopyright from "./components/FooterCopyright";
import "./App.css";

const App = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <PriceList />
      <Contact />
      <FooterCopyright />
    </div>
  );
};

export default App;
