import React from "react";
import { BiMath } from "react-icons/bi";
import { FaCode } from "react-icons/fa";
import { SlChemistry } from "react-icons/sl"

interface CardProps {
  title: string;
  prices: Array<number>;
}

const PriceListSolo: React.FC<CardProps> = ({ title, prices }) => {
  return (
    <div
      className="justify-center items-center flex flex-col w-full
      md:w-5/12
      md:rounded overflow-auto shadow-lg md:border-teal-500 md:border 
      h-[600px] hover:shadow-2xl hover:translate-y-[-10px] transition-transform
     duration-500 mt-5 border-b border-teal-500 dark:bg-cardamdark"
    >
      <div className="w-3/4 justify-center items-center flex font-medium text-2xl border-b border-teal-500 h-1/4">
        <h1>{title}</h1>
      </div>
      <div className="items-center flex flex-row justify-center h-1/4 w-full">
        <h1 className="w-1/2">POZIOM ROZSZERZONY</h1>
        <h1 className="w-1/2">{prices[0]}PLN/H lub {prices[4]}PLN/45min</h1>
      </div>
      <div className="items-center flex flex-row justify-center h-1/4 w-full">
        <h1 className="w-1/2">POZIOM PODSTAWOWY</h1>
        <h1 className="w-1/2">{prices[1]}PLN/H lub {prices[3]}PLN/45min</h1>
      </div>
      <div className="items-center flex flex-row justify-center h-1/4 w-full">
        <h1 className="w-1/2">ZAJĘCIA W PARZE</h1>
        <h1 className="w-1/2">{prices[2]}PLN/H</h1>
      </div>
    </div>
  );
};

const PriceListGroup: React.FC<CardProps> = ({ title, prices }) => {
  return (
    <div
      className="justify-center items-center flex flex-col w-full 
      md:w-5/12
      md:rounded overflow-auto shadow-lg md:border-teal-500 md:border 
      h-[600px] hover:shadow-2xl hover:translate-y-[-10px] transition-transform
      duration-500 mt-5 border-teal-500 border-b dark:bg-cardamdark"
    >
      <div className="w-3/4 justify-center items-center flex font-medium text-2xl border-b border-teal-500 h-1/4">
        <h1>{title}</h1>
      </div>
      <div className="items-center flex flex-row justify-center h-1/4 w-full">
        <h1 className="w-1/2">PRZYGOTOWANIA MATURALNE</h1>
        <h1 className="w-1/2">{prices[0]}PLN/H</h1>
      </div>
      <div className="items-center flex flex-row justify-center h-1/4 w-full"> 
        <h1 className="w-1/2">PRZYGOTOWANIA DO EGZ. 8-KL.</h1>
        <h1 className="w-1/2">{prices[1]}PLN/H</h1>
      </div>
      <div className="items-center flex flex-row justify-center h-1/4 w-full">
        <h1 className="w-1/2">
          WEEKENDOWA<br/>SZKOŁA PROGRAMOWANIA (UCZNIOWIE SZKÓŁ ŚREDNICH)
        </h1>
        <h1 className="w-1/2">{prices[2]}PLN/H</h1>
      </div>
    </div>
  );
};

const PriceList: React.FC = () => {
  const soloPrices = [120, 100, 70, 90, 45];
  const groupPrices = [50, 50, 80];

  return (
    <div
      id="pricelist"
      className="font-Montserrat justify-center content-center flex flex-col  dark:bg-amdark dark:text-white"
    >
      <div className="text-center mb-10">
        <h3 className="font-medium text-3xl mt-20">CENNIK</h3>
      </div>
      <div className="flex flex-col justify-center items-center space-y-4 mb-10">
        <div className="flex flex-row justify-center items-center"><BiMath size="30"></BiMath><p className="mx-5">Matematyka</p></div>
        <div className="flex flex-row justify-center items-center"><FaCode size="30"></FaCode><p className="mx-5">Informatyka</p></div>
        <div className="flex flex-row justify-center items-center"><SlChemistry size="30"></SlChemistry><p className="mx-5">Chemia</p></div>
      </div>
      <div className="text-center flex flex-col md:flex-row md:space-x-10 justify-center items-center mb-20">
        <PriceListSolo title="ZAJĘCIA INDYWIDUALNE" prices={soloPrices} />
        <PriceListGroup title="ZAJĘCIA GRUPOWE" prices={groupPrices} />
      </div>
    </div>
  );
};

export default PriceList;
