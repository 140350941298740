import React from "react";
import TeamMember from "./TeamMember";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faEye,
  faBook,
  faComments,
  faPhone,
  faPeopleGroup
} from "@fortawesome/free-solid-svg-icons";
import { MdGroups } from "react-icons/md"
import gabriel from "../assets/tutors/gabriel.jpg";
import joanna from "../assets/tutors/joanna.png";

const tutors = [
  {
    name: "Joanna",
    surname: "Nowak-Włodyga",
    speciality: "Chemia",
    description:
      "Jestem magistrem farmacji, absolwentkom Collegium Medicum z wieloletnim doświadczeniem w nauczaniu chemii. Chemia to moja pasja a zajęcia prowadzę w sposób prosty i zrozumiały. Co roku pod moimi skrzydłami do matury rozszerzonej z chemii przygotowują się z powodzeniem przyszli lekarze, weterynarze, chemicy i czy farmaceuci.",
    img: joanna,
    socialLinks: [
      {
        href: "https://www.linkedin.com/in/joanna-nowak-w%C5%82odyga-381819282/",
        icon: FaLinkedinIn,
      },
      {
        href: "https://www.facebook.com/profile.php?id=100001767911707",
        icon: FaFacebookF,
      },
    ],
  },
  {
    name: "Gabriel",
    surname: "Nowak",
    speciality: "Matematyka, informatyka",
    description:
      "Jestem absolwentem Uniwersytetu Jagiellońskiego a obecnie magistrantem kończącym drugi kierunek studiów - informatykę na Uniwersytecie Ekonomicznym w Katowicach. Jako założyciel Akademii oraz korepetytor z niemal dziesięcioletnim stażem, przygotowuję z powodzeniem uczniów do najtrudniejszych (według statystyk) matur rozszerzonych z matematyki oraz informatyki.",
    img: gabriel,
    socialLinks: [
      {
        href: "https://www.linkedin.com/in/gabriel-nowak-a83161124/",
        icon: FaLinkedinIn,
      },
      {
        href: "https://www.facebook.com/profile.php?id=100045040252671",
        icon: FaFacebookF,
      },
    ],
  },
];

const About: React.FC = () => {
  return (
    <div
      id="about"
      className="flex flex-col items-center font-Montserrat dark:text-white dark:bg-amdark "
    >
      <div className="text-center">
        <h3 className="font-medium text-3xl mt-20">O NAS</h3>
      </div>
      <div className="flex flex-wrap justify-center my-10">
        {tutors.map((tutor, index) => (
          <TeamMember key={index} {...tutor} />
        ))}
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-center w-full">
        <div className="flex flex-col items-center py-10 px-5 lg:w-1/3">
          <FontAwesomeIcon
            icon={faBullseye}
            size="6x"
            className="mb-5 text-teal-600"
          />
          <p className="text-center">
            Naszą misją jest dostarczenie najwyższej jakości usług edukacyjnych,
            które pomagają uczniom osiągnąć ich pełny potencjał. Wierzymy, że
            każdy ma zdolność do nauki, a naszą rolą jest wspieranie i
            inspirowanie do ciągłego rozwoju.
          </p>
        </div>
        <div className="flex flex-col items-center py-10 px-5 lg:w-1/3">
          <FontAwesomeIcon
            icon={faEye}
            size="6x"
            className="mb-5 text-teal-600"
          />
          <p className="text-center">
            Naszą wizją jest stworzenie społeczności uczniów, którzy są pewni
            swoich umiejętności i gotowi na przyszłe wyzwania. Dążymy do tego,
            aby edukacja była dostępna, angażująca i dostosowana do
            indywidualnych potrzeb każdego ucznia.
          </p>
        </div>
        <div className="flex flex-col items-center py-10 px-5 lg:w-1/3">
          <FontAwesomeIcon
            icon={faPeopleGroup}
            size="6x"
            className="mb-5 text-teal-600"
          />
          <p className="text-center">
          Naszą kadrę korepetytorską stanowią studenci i
  absolwenci najlepszych uczelni w całej Polsce
(od Krakowa po Gdańsk) oraz laureaci olimpiad
czy stypendyści MEN. Mimo swojego młodego
wieku, większość naszych korepetytorów ma co
najmniej kilka lat doświadczenia w prowadzeniu
zajęć, świeżą wiedzę i dzięki braku bariery wieku
- świetny kontakt z podopiecznym. Dokładamy
wszelkich starań, aby ciągle podnosić jakość
naszej kadry i warsztat korepetytorski poprzez
wewnętrzne szkolenia i wprowadzanie metod
efektywnej nauki do współprac z uczniami.
          </p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-center w-full">
        <div className="flex flex-col items-center py-10 px-5 lg:w-1/3">
          <FontAwesomeIcon
            icon={faBook}
            size="6x"
            className="mb-5 text-teal-600"
          />
          <p className="text-center">
          Stosujemy innowacyjne metody
nauki. Współpraca z nami to nie
same korki. Mocno wierzymy w
Zasadę Pareta (80% wyników
generowanych jest przez 20%
kluczowych działań). We
współpracy mocno stawiamy na
odpowiednie podejście do
nauki: postawienie jasnego celu
(wg metodologii SMART),
pomoc w planowaniu nauki,
dobór odpowiednich narzędzi
(zadań/podręczników) czy
wreszcie pomoc we wdrożeniu
planu w życie poprzez
cotygodniowe spotkania oraz
pomoc w realizacji zadań
domowych. Szacujemy, że
uczeń współpracując z nami
oszczędza kilka godzin
tygodniowo, skupiając się stricte
na tym, co jest mu niezbędne do
zdania matury czy egzaminu
ósmoklasisty z wybitnym
wynikiem
          </p>
        </div>
        <div className="flex flex-col items-center py-10 px-5 lg:w-1/3">
          <FontAwesomeIcon
            icon={faComments}
            size="6x"
            className="mb-5 text-teal-600"
          />
          <p className="text-center">
          Jesteśmy dostępni dla
swoich podopiecznych
również pomiędzy
cotygodniowymi
spotkaniami i chętnie
odpowiadamy na
ewentualne pytania
dotyczące materiału
szkolnego. Stały kontakt z
uczniem to dla nas
podstawa
          </p>
        </div>
        <div className="flex flex-col items-center py-10 px-5 lg:w-1/3">
          <FontAwesomeIcon
            icon={faPhone}
            size="6x"
            className="mb-5 text-teal-600"
          />
          <p className="text-center">
            Jeśli masz jakiekolwiek pytania lub chcesz dowiedzieć się więcej o
            naszych usługach, skontaktuj się z nami. Jesteśmy dostępni
            telefonicznie, mailowo oraz poprzez nasze media społecznościowe.
            Chętnie odpowiemy na wszystkie pytania i pomożemy znaleźć najlepsze
            rozwiązanie edukacyjne dla Ciebie.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
