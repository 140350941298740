import React, { useState, useEffect } from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import logo from "../assets/logo.png";

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("theme") === "dark";
  });


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDarkMode= () => {
    setDarkMode(!darkMode);
  }
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  });

  
  return (
    <nav className="z-50 dark:text-white bg-amdark dark:bg-amdark text-white shadow-lg lg:flex lg:items-center lg:justify-between font-Montserrat top-0 sticky">
      <div className="flex justify-between items-center p-4 lg:p-0">
        <span className="font-light lg:font-light text-xs lg:text-2xl">
          <img src={logo} alt="logo" className="inline h-8 lg:h-12 mb-1" />
          <h1 className="ml-3 inline">AKADEMIA MATURZYSTY</h1>
        </span>
        <div className="flex items-center justify-end">
        <button
          className="lg:hidden font-Inter text-lg px-1 py-1 rounded-full bg-black text-white  hover:bg-teal-300 transition"
          onClick={toggleMenu}
        >
          MENU
        </button>
        <button className="lg:hidden px-2 py-1" onClick={toggleDarkMode}>
          {darkMode ? <FaSun className="h-6 w-6 text-yellow-200" /> : <FaMoon className="h-6 w-6 text-gray-300" />}
        </button>
        </div>
      </div>
      <ul className="hidden lg:flex lg:flex-row lg:items-center lg:justify-between flex-grow">
        <li className="lg:ml-20">
          <a
            href="#about"
            className="text-xl hover:text-cyan-300 transition duration-500"
          >
            O NAS
          </a>
        </li>
        <li className="lg:mx-2">
          <a
            href="#pricelist"
            className="text-xl hover:text-cyan-300 transition duration-500"
          >
            CENNIK
          </a>
        </li>
        <li className="lg:mx-2">
          <a
            href="#contact"
            className="text-xl hover:text-cyan-300 transition duration-500"
          >
            KONTAKT
          </a>
        </li>
        <li className="lg:mr-5">
        <button className="text-lg  px-2 py-1 " onClick={toggleDarkMode}>
          {darkMode ? <FaSun className="h-6 w-6 text-yellow-200" /> : <FaMoon className="h-6 w-6 text-gray-300 " />}
        </button>
        </li>
      </ul>
      {isMenuOpen && (
        <ul className="duration-500 dark:bg-amdark z-20 lg:hidden flex flex-col items-center bg-white shadow-md absolute top-16 left-0 w-full">
          <li className="w-full text-center py-2 border-b border-gray-200">
            <a
              href="#about"
              className="text-xl hover:text-cyan-300 transition duration-500"
            >
              O NAS
            </a>
          </li>
          <li className="w-full text-center py-2 border-b border-gray-200">
            <a
              href="#pricelist"
              className="text-xl hover:text-cyan-300 transition duration-500"
            >
              CENNIK
            </a>
          </li>
          <li className="w-full text-center py-2 border-b border-gray-200">
            <a
              href="#contact"
              className="text-xl hover:text-cyan-300 transition duration-500"
            >
              KONTAKT
            </a>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
